<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <main v-else>
      <h1 class="mr-sm-4 header-tablepage">Branch Type Management</h1>

      <b-container class="no-gutters bg-white border-red mt-3">
        <div class="py-3">
          <b-row>
            <b-col lg="8" md="7" sm="12" xs="12">
              <InputText
                textFloat="Name"
                placeholder="Name"
                type="text"
                name="Name"
                isRequired
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
                v-model="form.name"
              />
            </b-col>
            <b-col lg="8" md="7" sm="12" xs="12">
              <InputText
                textFloat="Receipt No."
                placeholder="Receipt No."
                type="text"
                name="receipt_no"
                :isRequired="form.is_fullpos == 1"
                :isValidate="$v.form.receipt_no.$error"
                :v="$v.form.receipt_no"
                v-model="$v.form.receipt_no.$model"
                :notAcceptThai="true"
              />
            </b-col>
            <b-col lg="8" md="7" sm="12" xs="12">
              <InputText
                textFloat="Void No."
                placeholder="Void No."
                type="text"
                name="void_no"
                :isRequired="form.is_fullpos == 1"
                :isValidate="$v.form.void_no.$error"
                :v="$v.form.void_no"
                v-model="$v.form.void_no.$model"
                :notAcceptThai="true"
              />
            </b-col>
            <b-col lg="8" md="7" sm="12" xs="12">
              <InputText
                textFloat="Return No."
                placeholder="Return No."
                type="text"
                name="return_no"
                :isRequired="form.is_fullpos == 1"
                :v="$v.form.return_no"
                :isValidate="$v.form.return_no.$error"
                v-model="$v.form.return_no.$model"
                :notAcceptThai="true"
              />
            </b-col>
            <b-col lg="8" md="7" sm="12" xs="12">
              <InputText
                textFloat="Digit of Running No."
                placeholder="Digit of Running No."
                type="number"
                name="digit_of_running_no"
                :isRequired="form.is_fullpos == 1"
                :v="$v.form.digit_running_no"
                :isValidate="$v.form.digit_running_no.$error"
                v-model="form.digit_running_no"
              />
            </b-col>
            <b-col lg="8" md="7" sm="12" xs="12">
              <b-form-checkbox
                id="allowe-change"
                v-model="form.is_fullpos"
                :value="1"
                :unchecked-value="0"
                name="allowe-change"
              >
                <b>Full Function POS</b>
              </b-form-checkbox>
            </b-col>
            <b-col lg="8" md="7" sm="12" xs="12">
              <b-form-checkbox
                id="redeem-document"
                v-model="form.redeem_document"
                :value="1"
                :unchecked-value="0"
                name="redeem-document"
              >
                <b>Redeem Document</b>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <section>
            <div class="title">Accept Variable</div>
            <p>
              {BC} : Branch Code <br />
              {DD} : 2 Digit of Day of Transaction eg. 01,02,29 <br />
              {MM} : 2 Digit of Month of Transaction eg. 01,02,12 <br />
              {YY} : 2 Digit of Year of Transaction eg. Year 2020 : 20,22,23
            </p>
          </section>
        </div>
      </b-container>

      <FooterAction @submit="saveForm()" routePath="/branchType" />
    </main>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
const eng_number = (id) => {
  if (id.match("[a-zA-Z\s0-9-_#{}]+$")) {
    return true;
  }
  if (thai_only(id)) {
    return false;
  }
  return true;
};
const thai_only = (val) => {
  if (val) {
    if (val.match("[ก-๏\s]+$")) {
      return true;
    }
  }
  return false;
};
const useVariable = (val, form) => {
  if (form.is_fullpos == 1) {
    if (val.match("(?=.*{DD})(?=.*{BC})(?=.*{MM})(?=.*{YY})")) {
      return true;
    }
    return false;
  }
  return true;
};
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        receipt_no: {
          eng_number,
          required: requiredIf(function () {
            return this.form.is_fullpos == 1;
          }),
          useVariable,
        },
        void_no: {
          required: requiredIf(function () {
            return this.form.is_fullpos == 1;
          }),
          eng_number,
          useVariable,
        },
        return_no: {
          required: requiredIf(function () {
            return this.form.is_fullpos == 1;
          }),
          eng_number,
          useVariable,
        },
        digit_running_no: {
          required: requiredIf(function () {
            return this.form.is_fullpos == 1;
          }),
        },
      },
    };
  },

  computed: {
    mode() {
      return this.$route.params.id != 0 ? true : false;
    },
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      form: {
        branch_type_id: this.$route.params.id,
        name: "",
        receipt_no: "",
        void_no: "",
        return_no: "",
        digit_running_no: "",
        is_fullpos: 0,
      },
      optionsRole: [],
    };
  },
  created() {
    if (this.mode) {
      this.getData();
    }
  },
  destroyed() {},
  methods: {
    async getData() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_API}/branch/GetBranchType/${this.form.branch_type_id}`
      );

      this.form = res.data.detail;
    },

    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.form.digit_running_no = this.form.digit_running_no || 0;
      this.$EventBus.$emit("showLoading");
      var request;
      if (this.mode) {
        request = this.$Axios.put(
          `${process.env.VUE_APP_API}/branch/UpdateBranchType`,
          this.form
        );
      } else {
        request = this.$Axios.post(
          `${process.env.VUE_APP_API}/branch/CreateBranchType`,
          this.form
        );
      }
      request
        .then((res) => {
          if (res.data.result) {
            this.successAlert().then(() => this.$router.push("/branchType"));
          } else {
            this.errorAlert(res.data.message);
          }
        })
        .catch((err) => {
          this.errorAlert(err.message);
        });
      this.$EventBus.$emit("hideLoading");
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  margin: 0.5rem 0;
}
</style>
